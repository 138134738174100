<template>
   <div id="mifiel-contract-container"></div>
</template>

<script>
export default {
   props: ["id", "wid"],
   mounted() {
      window.mifiel.widget({
         widgetId: this.$route.params.wid,
         appendTo: 'mifiel-contract-container',
         successBtnText: 'Continuar',
         onSuccess: {
            // here the code you want to execute after the signer successfully sign and click the button could be an url  'mifiel.com' or a function() {}
            callToAction: function () {
               this.addSuccessNotif(null, "Se ha firmado el contrato de manera correcta, puedes cerrar esta pestaña.");
               this.$store.commit("SET_MIFIEL_CM_SUPPLIER_PROCESS_IS_FINISHED", true);
            }
         },
         onError: {
            callToAction: function () {
               this.failedOperationNotif();
            }
         },
      });
   }
}
</script>
